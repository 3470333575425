const KEY = 'lanlos_cart_data'

function getConfigOption() {
	return eval(localStorage.getItem(KEY))
}

function setConfigOption(data) {
	try {
		localStorage.setItem(KEY,JSON.stringify(data))
	} catch (e) {
		console.log("setConfigOption: ", e);
	}

}


const state = () => {
	let cartList = getConfigOption() || []
	return {
		cartList,
	}
}
const getters = {
	getCartList: (state) => state.cartList.filter(i => !i.buyNow),
	getBuyList: (state) => state.cartList.filter(i => i.buyNow),
}
const mutations = {
	setCartList(state, cartList) {
		state.cartList = cartList;
		setConfigOption(cartList)
	},
}

const actions = {
	addCart({
		state,commit
	}, params) {
		let info = params.info
		let num = params.num

		let cartList = state.cartList
		info.id = Date.now()
		info.num = num
		cartList.push(info)

		/*let flag = false
		if (info.id) { // 已有数据
			cartList.map((i) => {
				if (i.id === info.id) {
					i = info
					flag = true
				}
				return i
			})
		} else {
			cartList.map((i) => {
				if (i.product_id === info.product_id && i.spec_sku_id === info.spec_sku_id) { // 已存在该规格商品
					i.num += num
					flag = true
				}
				return i
			})
		}

		if (!flag) {
			info.id = Date.now()
			info.num = num
			cartList.push(info)
		}*/

		commit('setCartList', cartList)
	},
	delCart({state,commit}, id) {
		let cartList = state.cartList
		let ids = cartList.map(i => i.id)
		if (ids.indexOf(id) > -1) {
			cartList.splice(ids.indexOf(id), 1)
			commit('setCartList', cartList)
		}
	},
	clearCart({state,commit}, buyNow) {
		if (buyNow) {
			commit('setCartList', state.cartList.filter(i => !i.buyNow))
		} else {
			commit('setCartList', state.cartList.filter(i => i.buyNow))
		}
	}
}
export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
