<template>
    <footer class="footer_area">
        <div class="footer_top bg-cat--1">
            <div class="container">
                <div class="row">
                    <!-- Start Single Footer -->
<!--                    <div class="col-lg-3 col-sm-6 col-12 mb&#45;&#45;50">-->
<!--                        <div class="footer_widget">-->
<!--                            <h2 class="ft_widget_title">Los Angeles</h2>-->
<!--                            <div class="textwidget">-->
<!--                                <p>254 Roselen de Square-->
<!--                                    <br>Century Street, Haven Tower-->
<!--                                    <br>Los Angeles, USA</p>-->
<!--                                <p>01254 658 987, 02365 987 456</p>-->
<!--                                <p>info@example.com-->
<!--                                    <br>www.example.com</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <!-- End Single Footer -->
                    <!-- Start Single Footer -->
<!--                    <div class="col-lg-3 col-sm-6 col-12 mb&#45;&#45;50">-->
<!--                        <div class="footer_widget">-->
<!--                            <h2 class="ft_widget_title">Manchester</h2>-->
<!--                            <div class="textwidget">-->
<!--                                <p>254 Roselen de Square-->
<!--                                    <br>Century Street, Haven Tower-->
<!--                                    <br>Los Angeles, USA</p>-->
<!--                                <p>01254 658 987, 02365 987 456</p>-->
<!--                                <p>info@example.com-->
<!--                                    <br>www.example.com</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <!-- End Single Footer -->
                    <!-- Start Single Footer -->
                    <div class="col-lg-12 col-sm-6 col-12 mb--50">
                        <div class="footer_widget">
                            <h2 class="ft_widget_title">{{ info.web_title }}</h2>
                            <div class="textwidget">
                                <p>版权所有 本站内容未经书面许可,禁止一切形式的转载</p>
                                <p>{{ info.contact }}</p>
                                <p>{{ info.email }}</p>
                                <p v-show="info.copyright">Copyright © {{ info.copyright }}, All Rights </p>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Footer -->
                    <!-- Start Single Footer -->
<!--                    <div class="col-lg-6 col-sm-6 col-12 mb&#45;&#45;50">-->
<!--                        <div class="footer_widget">-->
<!--                            <h2 class="ft_widget_title">Newsletter</h2>-->
<!--                            <div class="textwidget">-->
<!--                                <div class="input_box">-->
<!--                                    <input type="email" placeholder="Enter your email here">-->
<!--                                    <button>Subscribe</button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <!-- End Single Footer -->
                </div>
            </div>
        </div>

<!--        <div class="copyright bg-cat&#45;&#45;2">-->
<!--            <div class="container">-->
<!--                <div class="row align-items-center">-->
<!--                    <div class="col-lg-6">-->
<!--                        <div class="copy_text">-->
<!--                            <p>Copyright © 2018, All Rights <a href="http://www.bootstrapmb.com/">Reserved</a>.</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6">-->
<!--                        <ul class="footer_menu d-flex justify-content-end">-->
<!--                            <li>-->
<!--                                <a href="#">Product Tracking</a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="#">Terms & Conditions</a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="#">Contact</a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

    </footer>
</template>

<script>
import { mapActions } from 'vuex'
import request from "@/utils/request";

export default {
    name: 'Footer',
    data() {
        return {
            info: {
                web_title: '',
                contact: '',
                email: '',
                copyright: '',
            }
        }
    },
    created() {
        request.post('index/basis').then(({data}) => {
            this.info = data
        })
    },
    methods: {

    }
}
</script>
