<template>
  <!-- Wrapper -->
  <div id="wrapper" class="wrapper">

    <!-- Header -->
    <Header/>

    <!-- Cart Overlay -->
    <div class="body_overlay"></div>
    <!--// Header -->
    <!--{{pageWidth}}-->
    <div class="col-lg-12 col-md-12 col-sm-12 top-box">
      <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12" v-loading="loading"
           style="display: flex;flex-direction: column">
        <div class="cate">
          <div class="sidebar_widget widget_categories c-div" style="width: 100%">
            <h4 class="cate-title">
              <img src="@/assets/images/homeImg/changgui.png" style="width: 24px;height: 24px">
              常规商品
            </h4>
            <div class="cate-box">
              <div :style="{padding: '5px', width: '33.3333%'}"
                   v-for="(item, index) in categoryList1" :key="index">
                <div class="button cate-item"
                     @click="selectCategory(item, '0')">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cate">
          <div class="sidebar_widget widget_categories c-div" style="align-self: flex-end">
            <h4 class="cate-title">
              <img src="@/assets/images/homeImg/dingzhi.png" style="width: 24px;height: 24px">
              定制商品</h4>
            <div class="cate-box">
              <div :style="{padding: index<categoryList1.length-3?'5px':'5px 5px 0', width: '33.3333%'}"
                   v-for="(item, index) in categoryList2" :key="index">
                <div class="button cate-item" :style="{marginBottom: 0}"
                     @click="selectCategory(item, '0')">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!--            <div class="cate-box">
                          <div class="button cate-item" v-for="(item, index) in categoryList2" :key="index"
                               @click="selectCategory(item, '1')" :style="{marginBottom: index<categoryList2.length-3?'5px':0}">
                            {{ item.name }}
                          </div>
                        </div>-->
          </div>
        </div>
      </div>

      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
        <div class="slider_area slide_active">
          <!-- Start Single Slide -->
          <el-carousel class="carousel-box button" trigger="click" arrow="never" ref="slideCarousel"
                       :height="screenWidth >= 912 ? '100%':`${screenWidth}px`">
            <el-carousel-item class="carousel-item-box" v-for="(item, index) in banner" :key="index">
              <!--              <img class="carousel-img" :src="item.url" alt="" @click="clickCarousel(item)">-->
              <el-image
                  style="width: 100%;height:auto"
                  @click="clickCarousel(item)"
                  class="carousel-img"
                  :src="item.url"
                  fit="cover"></el-image>
            </el-carousel-item>
          </el-carousel>
          <!-- End Single Slide -->
        </div>
      </div>


    </div>


    <!-- Start Slider Area -->
    <!--        <div class="slider_area slide_active">-->
    <!--            &lt;!&ndash; Start Single Slide &ndash;&gt;-->
    <!--            <el-carousel class="button" trigger="click" arrow="never" ref="slideCarousel">-->
    <!--                <el-carousel-item v-for="(item, index) in banner" :key="index">-->
    <!--                    <img :src="item.url" alt="" @click="clickCarousel(item)">-->
    <!--                </el-carousel-item>-->
    <!--            </el-carousel>-->
    <!--            &lt;!&ndash; End Single Slide &ndash;&gt;-->
    <!--        </div>-->
    <!-- End Slider Area -->


    <!-- Start Product Area -->
    <div class="product_area section-pt-xl section-pb-xl bg-white">
      <div class="container container-box">
        <div class="row">
          <div class="col-lg-12">
            <div class="section_title text-center">
              <h2 style="letter-spacing: 16px">{{ title_content.new_title }}</h2>
              <p>{{ title_content.new_content }}</p>
            </div>
          </div>
        </div>

      </div>
      <div class="row mt--20">
        <!-- Start Single Product -->
        <div class="row single_content tab-pane fade show active  rec-pro-box" id="item1" role="tabpanel"
             style="justify-content: center;margin: auto">
          <!-- Start Single Product -->
          <product-item size="small" :info="newItem" v-for="(newItem, newIndex) in news" :key="newIndex"/>
          <!-- End Single Product -->
        </div>
        <!-- End Single Product -->
      </div>
    </div>
    <!-- End Product Area -->
    <!-- Start Banner Area -->

    <div class="container container-box">
      <div class="row mb--30">
        <div class="col-lg-12">
          <div class="section_title text-center">
            <h2 style="letter-spacing: 16px">{{ title_content.rec_title }}</h2>
            <p>{{ title_content.rec_content }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="banner_area">
      <div class="row row--2">
        <div class="col-lg-4 col-sm-6 col-12" v-for="(recItem, recIndex) in recs" :key="recIndex">
          <div class="banner">
            <div class="thumb" @click="toItem(recItem.sn)">
                <img :src="recItem.rec_image[0]['url']" alt="" width="100%" height="100%">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area -->

    <!-- Start todo 20240410去掉 新款商品 Product Area -->
    <!--        <div v-loading="loading" class="product_area section-pt-xl section-pb-xl">-->
    <!--            <div class="container container-box">-->
    <!--                <div class="tab_content">-->

    <!--                    &lt;!&ndash; 搜索框&ndash;&gt;-->
    <!--                    <div class="row">-->
    <!--                        <div class="col-lg-12">-->
    <!--                            <div class="section_title text-center">-->
    <!--                                <h2>新 款 商 品</h2>-->
    <!--                                <p>易视康眼镜，品质与时尚并存，独特选择，无限惊喜。打造您独特的风格，让您成为时尚焦点！</p>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                    <div class="row">-->
    <!--                        <div class="col-lg-12">-->
    <!--                            <div class="product_tab_name justify-content-center nav" role="tablist">-->
    <!--                                <a class="active button" @click="searchTabs('')" data-toggle="tab">全部</a>-->
    <!--                                <a v-for="(it, i) in tabs" class="button" @click="searchTabs(it.value)" data-toggle="tab">{{it.label}}</a>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->

    <!--                    &lt;!&ndash; Start Single Content &ndash;&gt;-->
    <!--                    <div class="row single_content tab-pane fade show active" id="item1" role="tabpanel">-->
    <!--                        &lt;!&ndash; Start Single Product &ndash;&gt;-->
    <!--                        <product-item :info="item" v-for="(item, index) in productList1" :key="index"/>-->
    <!--                        &lt;!&ndash; End Single Product &ndash;&gt;-->
    <!--                    </div>-->
    <!--                    &lt;!&ndash; End Single Content &ndash;&gt;-->
    <!--                    <ul class="pagination_style">-->
    <!--                        <el-pagination layout="prev, pager, next" background :page-size="pageData1.pageSize" :total="total1" @current-change="changePage1"></el-pagination>-->
    <!--                    </ul>-->

    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!-- End 新款商品 Product Area -->

    <!-- Start todo 20240410去掉 定制商品 Product Area -->
    <!--        <div v-loading="loading" class="product_area section-pb-xl pt&#45;&#45;10">-->
    <!--            <div class="container container-box">-->
    <!--                <div class="tab_content">-->
    <!--                    <div class="row">-->
    <!--                        <div class="col-lg-12">-->
    <!--                            <div class="section_title text-center">-->
    <!--                                <h2>定 制 商 品</h2>-->
    <!--                                <p>易视康眼镜，品质与时尚并存，独特选择，无限惊喜。打造您独特的风格，让您成为时尚焦点！</p>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                    &lt;!&ndash; Start Single Content &ndash;&gt;-->
    <!--                    <div class="row single_content tab-pane fade show active" id="item1" role="tabpanel">-->
    <!--                        &lt;!&ndash; Start Single Product &ndash;&gt;-->
    <!--                        <product-item :info="item" v-for="(item, index) in productList2" :key="index"/>-->
    <!--                        &lt;!&ndash; End Single Product &ndash;&gt;-->
    <!--                    </div>-->
    <!--                    &lt;!&ndash; End Single Content &ndash;&gt;-->
    <!--                    <ul class="pagination_style">-->
    <!--                        <el-pagination layout="prev, pager, next" background :page-size="pageData2.pageSize" :total="total2" @current-change="changePage2"></el-pagination>-->
    <!--                    </ul>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!-- End 定制商品 Product Area -->


    <!-- Start todo 20240410去掉 How To Work Area -->

    <!--    <div class="work_area bg-dark">
          <div class="row row&#45;&#45;0 align-items-center">
            <div class="col-lg-12">
              <div class="banner">
                <div class="thumb">
                  <a @click="goLink(product_guide[0].link)">
                    <img :src="product_guide[0].url" alt="">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>-->

    <!-- End How To Work Area -->


    <!-- Start Shipping Service -->

    <div class="shipping_service bg_image--3 ptb--90">
      <div class="shipping_wrap">
        <div class="row">

          <!-- Start Single -->
          <div class="col-lg-6 col-xl-3 col-sm-6 col-12 service" :class="getClass(i)" v-for="(it, i) in description">
            <div class="service item_4">
              <div class="icon">
                <img :src="it.image[0].url" alt="">
              </div>
              <div class="content">
                <h3 class="tal">{{ it.name }}</h3>
                <p class="tal">{{ it.desc }}</p>
              </div>
            </div>
          </div>
          <!-- End Single -->

        </div>
      </div>
    </div>

    <!-- End Shipping Service -->
    <!-- Start Footer Area -->
    <Footer/>
    <!-- End Footer Area -->

  </div>
  <!--// Wrapper -->
</template>

<script>
import Header from "@/views/components/Header.vue";
import Footer from "@/views/components/Footer.vue";
import request from "@/utils/request";
import ProductItem from "@/views/components/ProductItem.vue";

export default {
  name: 'Home',
  computed: {
    pageWidth() {
      return window.innerWidth
    }
  },
  components: {Header, Footer, ProductItem},
  data() {
    return {
      banner: [],
      screenWidth: (document.documentElement.clientWidth * 668) / 1620,
      // screenWidth: '100%',
      pageData1: {
        search: '',
        pageSize: 12,
        pageNo: 1,
        filter: {
          product_type: 0
        }
      },
      pageData2: {
        search: '',
        pageSize: 12,
        pageNo: 1,
        filter: {
          product_type: 1
        }
      },
      loading: false,
      productList1: [],
      productList2: [],
      news: [],
      recs: [],
      tabs: [],
      total1: 0,
      total2: 0,
      product_guide: [{
        url: ''
      }],
      description: [],

      categoryList1: [],
      categoryList2: [],
      title_content: {}
    }
  },
  mounted() {
    if (document.documentElement.clientWidth > 575) {
      this.screenWidth = (document.documentElement.clientWidth * 0.7) * 860 / 1920;
    } else {
      this.screenWidth = (document.documentElement.clientWidth * 860) / 2000;
    }
    // window.onresize = () => {
    //     return (() => {
    //         // if (document.documentElement.clientWidth > 960) {
    //         //     this.screenWidth = (document.documentElement.clientWidth * 668) / 1620;
    //         // }
    //     })();
    // };
    this.slideBanner()
  },
  methods: {

    getData1() {
      request.post('shop/product/page', this.pageData1).then((res) => {
        this.productList1 = res.data.rows
        this.total1 = res.data.total
      }).catch((e) => {
      })
    },
    getData2() {
      request.post('shop/product/page', this.pageData2).then((res) => {
        this.productList2 = res.data.rows
        this.total2 = res.data.total
      }).catch((e) => {
      })
    },
    getBanner() {
      request.post('index/banner').then((res) => {
        this.banner = res.data.index_carousel
        this.title_content = res.data
      }).catch((e) => {
      })
    },
    getNewRec() {
      request.post('shop/product/newRec').then((res) => {
        this.news = res.data.news
        this.recs = res.data.recs
      }).catch((e) => {
      })
    },
    getDictionary() {
      request.post('index/dictionary', {key: 'ShopProductStyle'}).then((res) => {
        this.tabs = res.data.rows
      }).catch((e) => {
      })
    },
    getGuide() {
      request.post('index/banner', {key: 'app', val: 'product_guide'}).then((res) => {
        this.product_guide = res.data
      }).catch((e) => {
      })
    },
    getDescription(link) {
      request.post('index/description').then((res) => {
        this.description = res.data
      }).catch((e) => {
      })
    },
    getCategory() {
      let that = this
      request.post('shop/product/getCategory', {home: true}).then(({data}) => {
        that.categoryList1 = data.groove.slice(0, this.pageWidth <= 1180 ? 9 : 15)
        that.categoryList2 = data.custom.slice(0, this.pageWidth <= 1180 ? 9 : 15)
      })
    },
    goLink(link) {
      window.open(link)
    },
    addCart(id) {
      request.post('shop/cart/addCart', {
        num: 1,
        product_id: id,
        spec_sku_id: '',
      }).then((res) => {
        setTimeout(() => {
          this.$router.replace({
            path: '/cart',
          })
        }, 100)
      }).catch((e) => {
        console.log(e)
      })
    },
    // addCollect(id) {
    //     request.post('shop/user/relation/collect', {
    //         product_id: id,
    //     }).then((res) => {
    //         this.getData1()
    //     }).catch((e) => {
    //         console.log(e)
    //     })
    // },
    handleSearch(search) {
      this.$router.push(`shop-grid?search=${search}`)
    },
    changePage1(pageNo) {
      this.pageData1.pageNo = pageNo
      this.getData1();
    },
    changePage2(pageNo) {
      this.pageData2.pageNo = pageNo
      this.getData2();
    },
    toItem(id) {
      this.$router.push({
        name: 'single-product',
        params: {
          i: id
        }
      })
    },
    searchTabs(v) {
      this.pageData1.filter.style = v
      this.pageData1.pageNo = 1
      if (v === '') {
        delete this.pageData1.filter['style'];
      }
      this.getData1()
    },
    getClass(i) {
      if (i >= this.description.length - 1) {
        return 'item_4'
      } else {
        return ''
      }
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      let box = document.querySelector('.el-carousel__container');
      let startPointX = 0
      let stopPointX = 0
      //重置坐标
      let resetPoint = function () {
        startPointX = 0
        stopPointX = 0
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPointX === 0 || startPointX - stopPointX === 0) {
          resetPoint()
          return
        }
        if (startPointX - stopPointX > 0) {
          resetPoint()
          that.$refs.slideCarousel.next()
          return
        }
        if (startPointX - stopPointX < 0) {
          resetPoint()
          that.$refs.slideCarousel.prev()
          return
        }
      });
    },

    clickCarousel(item) {
      if (item.link) {
        if (item.link.indexOf('http') > -1) {
          window.open(item.link)
        } else {
          let preg = /(\S+)\?(\S+)=(\S+)/
          let link = item.link.match(preg)

          let params = {}
          if (link[2] && link[3]) {
            params[link[2]] = link[3]
          }
          this.$router.push({
            name: link[1],
            params
          })
        }
      }
    },

    selectCategory(item, type) {
      this.$router.push({
        name: 'shop-grid',
        params: {
          category_id: item.id,
        }
      });
    }
  },
  created() {
    this.loading = true
    // this.getData1()
    // this.getData2()
    this.getBanner()
    this.getNewRec()
    // this.getDictionary()
    this.getGuide()
    this.getDescription()
    this.getCategory()
    this.loading = false
  }
}
</script>

<style scoped>
.container-box {
  width: 90%;
  margin: auto;
  padding: 0;
}

.tal {
  text-align: left;
}
</style>

<style>
.el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #010101;
  border: 1px solid rgb(89, 89, 89);
  border-radius: 50%;
  opacity: 0.3;
}

.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: rgb(89, 89, 89);
  border-radius: 50%;
  opacity: 1;
}

.el-pagination.is-background.el-pager li:not(.disabled) {
//background-color:#fff;/*进行修改未选中背景和字体*/ //color:#fff;
}

.el-pagination.is-background.el-pager li:not(.disabled).active {
  background-color: rgb(63, 63, 63); /*进行修改选中项背景和字体*/
  color: #fff;
}

.el-pager li.active {
  background-color: rgb(63, 63, 63) !important;
  color: #fff !important; /*仅设置active时的文字颜色*/
}

.rec-pro-box {
  padding: 0 15px;
}

</style>

<style scoped>
.top-box {
//max-height: 600px; overflow: hidden; padding-top: 10px; width: 100%; display: flex;
}

.cate {
  display: flex !important;
//overflow: scroll; display: block; height: 50%; margin: 0; padding: 0;
}


.cate-item {
  float: left;
  border-radius: 3px;
  display: inline-block;
  background: rgba(238, 238, 238, 1);
//margin: 5px 0; padding: 5px; width: 100%; text-overflow: ellipsis; white-space: nowrap;
  overflow: hidden;
  color: rgba(51, 51, 51, 1);

  &:hover {
    background-color: rgb(240, 247, 255);
  }

  &.active {
    background: rgba(7, 86, 160, 1);
    color: #ffffff;
  }
}

.cate-title {
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    margin-top: 5px;
  }
}

.cate-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 575px) {
  .top-box {
    display: block;
  }

  .cate {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 912px) {
  .top-box {
    min-height: 500px;
  }

  .slider_area, .carousel-box {
    height: 100%;
  }

  /deep/ .el-carousel__container {
    height: 100% !important;

    /deep/ .carousel-img {
      height: 100% !important;

      img {
        object-fit: fill;
      }
    }
  }
}
</style>