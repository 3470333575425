import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
import LoginRegister from '../views/login-register.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/test.vue')
    },
    {
        path: '/test3',
        name: 'test3',
        component: () => import('../views/DropDown.vue')
    },
    {
        path: '/login-register',
        name: 'login-register',
        component: LoginRegister
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/register.vue')
    },
    {
        path: '/',
        name: 'index',
        component: Home
    },
    {
        path: '/index-2',
        name: 'index-2',
        component: () => import('../views/index-2.vue')
    },
    {
        path: '/index-3',
        name: 'index-3',
        component: () => import('../views/index-3.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue')
    },
    {
        path: '/problem', name: 'problem', component: () => import('../views/problem.vue')
    },
    {
        path: '/cart',
        name: 'cart',
        component: () => import('../views/cart.vue')
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('../views/checkout.vue')
    },
    {
        path: '/address',
        name: 'address',
        component: () => import('../views/address.vue')
    },
    {
        path: '/moreclick',
        name: 'moreclick',
        component: () => import('../views/moreclick.vue')
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: () => import('../views/my-account.vue')
    },
    // {
    //   path: '/shop',
    //   name: 'shop',
    //   component: () => import('../views/shop.vue')
    // },
    {
        path: '/shop-grid',
        name: 'shop-grid',
        component: () => import('../views/shop-grid.vue')
    },
    {
        path: '/single-product/:i',
        name: 'single-product',
        component: () => import('../views/single-product.vue')
    },
    {
        path: '/advertising/:i',
        name: 'advertising',
        component: () => import('../views/advertising.vue')
    },
    {
        path: '/wishlist',
        name: 'wishlist',
        component: () => import('../views/wishlist.vue')
    },
    {
        path: '/choose-address',
        name: 'choose-address',
        component: () => import('../views/choose-address.vue')
    },
    {
        path: '/footprint',
        name: 'footprint',
        component: () => import('../views/footprint.vue')
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/order.vue')
    },
    {
        path: '/order-detail',
        name: 'order-detail',
        component: () => import('../views/order-detail.vue')
    },
    {
        path: '/refund',
        name: 'refund',
        component: () => import('../views/refund.vue')
    },
    {
        path: '/refund-detail',
        name: 'refund-detail',
        component: () => import('../views/refund-detail.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/reset-password.vue')
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    // const isLoggedIn = localStorage.getItem("QBFast") !== null; // 自定义函数，用于检查用户是否已登录
    // const idToken = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('to.path', to.path === '/login-register')
    if (to.path === '/login-register') return next();
    // console.log('!isLoggedIn || !idToken?.user_name',idToken);
    // console.log('!isLoggedIn || !idToken?.user_name', !isLoggedIn || !idToken?.user_name)
    // if (!isLoggedIn || !idToken?.user_name) return next('/login-register');
    next()
});


const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误
//获取原型对象上的push函数

export default router
