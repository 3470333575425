<template>
  <header class="header chasmishco_header">
    <div class="container container-box">
      <div class="row align-items-center align-items-center-box">

        <!-- 搜索框-->
        <div class="col-xl-6 col-lg-6 col-md-6 logo-search-box">
          <div class="logo">
            <router-link to="/">
              <img :src="logo" alt="">
            </router-link>
          </div>
          <div v-if="show_search" class="sidebar_widget search-box">
            <div class="sidebar_search">
              <form>
                <input type="text" placeholder="输入关键字" v-model="search">
                <button @click="handleSearch"><i class="ti-search"></i></button>
              </form>
            </div>
          </div>

        </div>

        <!-- 菜单-->
        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="header_right_sidebar">

            <!--登录部分-->
            <div  v-if="!userinfo" class="login_account">
              <div class="account">
                <ul >
                  <li style="width: 100px">
                    <router-link class="menu-1" to="/login">登录</router-link>
                  </li>
                </ul>
              </div>
            </div>


            <!-- 菜单部分-->
            <div v-else-if="isPc && userinfo"  class="glass_toggle_menu">
              <nav class="mainmenu_nav mainmenu__nav">
                <ul class="main_menu">
                    <div class="login_account tran-box">

                      <div class="mini_cat_box">
                        <div class="shop_cart_icon shop_trigger shopping_basket header-cart">
                          <router-link to="/cart">
                            <span class="shop_count" v-show="cart_num > 0">{{ cart_num }}</span>
                            <span class="cart_text cart menu-1">购物车</span>
                          </router-link>
                        </div>
                      </div>

                      <li class="drop" v-for="(item, index) in menu" :key="index">
                        <a class="button menu-1" @click="execute(item)">{{ item.title }}</a>
                        <ul v-if="item.children !== undefined && item.children.length > 0" class="dropdown">
                          <li v-for="(it, i) in item.children" :key="i">
                            <a class="button" @click="execute(it)">{{ it.title }}</a>
                          </li>
                        </ul>
                      </li>

                    </div>
                </ul>
              </nav>

<!--              <div class="hamburger-box button mobile-toggle">-->
<!--                  <span class="mobile-toggle__icon"></span>-->
<!--              </div>-->
            </div>

            <!-- 汉堡按钮-->
<!--            <div v-if="isPc" class="hamburger-box">-->
<!--              <a class="button menu-1 burger-button">-->
<!--                <tasty-burger-button type="elastic" size="s" :active="false" v-on:toggle="onToggle" />-->
<!--              </a>-->
<!--            </div>-->

            <!-- Mobile Menu -->
            <div v-else class="mobile-menu d-block m-btn">
              <el-dropdown
                  trigger="click"
                  ref="dropdown"
                  :close-on-click-modal="false"
                  @visible-change="dropdownChange"
                  @command="handleCommand">
                <span class="el-dropdown-link">

                  <div class="hamburger-icon">
                    <div class="hamburger-icon-box">
                      <div class="hamburger-1" :class="isActiveHamburger1 ? '' : 'is-active'" @click="isActiveHamburger1 = !isActiveHamburger1">
                        <span class="line" />
                        <span class="line" />
                        <span class="line" />
                      </div>
                    </div>
                  </div>

<!--                  <tasty-burger-button type="spin" size="s" :active="isActive" v-on:toggle="onToggleM" />-->
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="shop-grid">商城</el-dropdown-item>
                  <el-dropdown-item command="my-account">个人中心</el-dropdown-item>
                  <el-dropdown-item command="cart">购物车</el-dropdown-item>
                  <el-dropdown-item command="order">订单</el-dropdown-item>
                  <el-dropdown-item command="refund">售后</el-dropdown-item>
                  <el-dropdown-item command="footprint">足迹</el-dropdown-item>
                  <el-dropdown-item command="wishlist">收藏</el-dropdown-item>
                  <el-dropdown-item command="about">关于</el-dropdown-item>
                  <el-dropdown-item command="problem">常见问题</el-dropdown-item>
                  <el-dropdown-item v-if="userinfo" command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- Mobile Menu -->
          </div>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import request from '@/utils/request'
import store from '@/store'
import { TastyBurgerButton } from 'vue-tasty-burgers'

export default {
  name: 'Header',

  components: {TastyBurgerButton},
  props: {
    show_search: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isPc: false,
      isM: false,
      logo: '../img/logo/logo1.png',
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      search: '',

      show_login: true,
      show_menu: false,

      menu: [
        {
          title: '商品',
          type: 'link',
          link: '/shop-grid',
        },
        {
          title: '关于',
          type: 'link',
          link: '/about',
        },
        {
          title: '常见问题',
          type: 'link',
          link: '/problem'
        },
        {
          title: '我的',
          type: '',
          link: '',
          children: [
            {
              title: '个人中心',
              type: 'link',
              link: '/my-account'
            },
            // {
            //   title: '购物车',
            //   type: 'link',
            //   link: '/cart'
            // },
            {
              title: '订单管理',
              type: 'link',
              link: '/order'
            },
            {
              title: '售后管理',
              type: 'link',
              link: '/refund'
            },
            {
              title: '我的足迹',
              type: 'link',
              link: '/footprint'
            },
            {
              title: '我的收藏',
              type: 'link',
              link: '/wishlist'
            },
            {
              title: '退出登录',
              type: 'logout',
              link: ''
            },
          ],
        }
      ],

      isActive: false,
      cart_num: 0,


      isActiveHamburger1: true,

    }
  },
  created() {
    request.post('index/basis').then(({data}) => {
      this.logo = data.logo[0]?.url ?? '../img/logo/logo1.png'
    })
    request.post('shop/cart/page').then(({data}) => {
      this.cart_num = data.length
    })
  },
  mounted () {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      console.log('手机端')
      this.isPc = false
      this.isM = true
    } else {
      console.log('pc端')
      this.isPc = true
      this.isM = false
    }
  },
  methods: {
    _isMobile () {
      console.log('navigator.userAgent',navigator.userAgent)
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    ...mapActions('user', ['logout']),
    onToggle (active) {
      if (this.show_login) {
        this.show_login = !this.show_login
        setTimeout(() => {
          this.show_menu = !this.show_menu
        }, 100)
      } else {
        this.show_menu = !this.show_menu
        setTimeout(() => {
          this.show_login = !this.show_login
        }, 400)
      }
    },
    execute(item) {
      if (item.type === 'link') {
        this.$router.push(item.link)
      }
      if (item.type === 'logout') {
        this.logout()
      }
    },
    handleSearch(search) {
      this.$router.push(`shop-grid?search=${this.search}`)
      // this.$emit("handleSearch", this.search)
    },
    onToggleM (e) {
      console.log('onToggleM', this.isActive)
    },
    dropdownChange(e){
        setTimeout(() => {
          this.isActiveHamburger1 = !e
        }, 100)
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout()
      } else {
        this.$router.push(command)
      }
    },
    checkUser() {
      console.log(this.userinfo)
      if (this.userinfo) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.container-box {
  width: 90%;
  margin: auto;
  padding: 0;
}

.mainmenu_nav {
  padding: 0;
  position: static;
}

.menu-1 {
  font-size: 18px !important;
  font-weight: bolder !important;
  padding: 0 15px;
}

.burger-button {
  margin-bottom: 5px;
}

@media only screen and (max-width: 575px) {
  .hamburger-box {
    display: none;
  }

  .align-items-center-box {
    margin-top: 20px;
  }
  .logo {
    margin: auto;
  }
}

.m-btn {
  margin: 0 10px 0 auto;
}

.mobile-menu-box {
  margin: 0 10px 0 auto;
  position: relative;
  z-index: 999;
  top: 10px;
  left: 10px;
  width: 50%;
  //height: 200px;
  background-color: #c69500;
}

.el-dropdown-menu {
  padding: 2px 0;
}

.el-dropdown-menu__item {
  letter-spacing: 0.5rem;
  background-color: rgb(5, 3, 27);
  font-weight: bolder;
  color: #f5f5f5;
  width: 200px;
}

</style>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0.5;
}

/* 定义动画 */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s;
}
.slide-enter, .slide-leave-to /* .slide-leave-active for <=2.1.8 */ {
  transform: translateX(50px);
  opacity: 0;
}

.tran-box {
  display: flex;
}

.cart {
  padding-left: 5px;
}

.logo-search-box {
  display: flex;
}

.logo {
  flex: 0.2;
}

.search-box {
  margin: auto;
  flex: 0.8;
}
</style>

<style lang="less" scoped>
.hamburger-icon {
  display: flex;
  width: 100%;
  height: 100%;

  .hamburger-icon-box {
    position: relative;
    flex: 1;
    display: table;
    margin-right: auto;
    //background-color: #f0f;
  }

  /* ---- ^ hamburger-1 ---- */
  .hamburger-1 {
    position: relative;
    //width: 100px;
    width: 50px;
    margin: auto;

    &:hover {
      cursor: pointer;
    }

    .line {
      //width: 40px;
      width: 30px;
      //height: 5px;
      height: 3px;
      background-color: #000;
      //border-radius: 5px;
      border-radius: 5px;
      display: block;
      margin: 7.5px auto;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    &.is-active .line:nth-child(1) {
      -webkit-transform: translateY(10.5px) rotate(45deg);
      -ms-transform: translateY(10.5px) rotate(45deg);
      -o-transform: translateY(10.5px) rotate(45deg);
      transform: translateY(10.5px) rotate(45deg);
    }

    &.is-active .line:nth-child(2) {
      opacity: 0;
    }

    &.is-active .line:nth-child(3) {
      -webkit-transform: translateY(-10.5px) rotate(-45deg);
      -ms-transform: translateY(-10.5px) rotate(-45deg);
      -o-transform: translateY(-10.5px) rotate(-45deg);
      transform: translateY(-10.5px) rotate(-45deg);
    }
  }
  /* ---- / hamburger-1 ---- */
}
</style>