import request from '@/utils/request'
import {SESSION_KEY} from "@/config/global";
import router from '@/router' // 引入Vue Router实例
// import { storage } from '@/utils/storage'
const state = {
    state: {
        token: localStorage.getItem('token') || null,
        isAuthenticated: false,
        userinfo: localStorage.getItem('userinfo') || null,
        lang: localStorage.getItem('languageSet') || 'cn',
        key: localStorage.getItem('ak')||SESSION_KEY,
    },
}
const getters = {
    token: (state) => state.token,
    userinfo: (state) => state.userinfo,
    key: (state) => state.key,
}
const mutations = {
    setToken(state, token) {
        state.token = token
        localStorage.setItem('token', token) // 将 token 存储到 localStorage 中
    },
    setKey(state, key) {
        state.key = key
        localStorage.setItem('ak', key) // 将 token 存储到 localStorage 中
    },
    removeToken(state) {
        state.token = null
        localStorage.removeItem('token') // 从 localStorage 中删除 token
        // localStorage.removeItem('languageSet')
    },
    removeKey(state) {
        state.key = null
        localStorage.removeItem('ak') // 从 localStorage 中删除 token
        // localStorage.removeItem('languageSet')
    },
    setIsAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated
    },
    setUserInfo(state, data) {
        state.userinfo = data || {}
        state.lang = data.lang || 'cn'
        localStorage.setItem('userinfo', JSON.stringify(data))
        localStorage.setItem('languageSet', data.lang)
    },
    removeUserInfo() {
        state.userinfo = null
        localStorage.removeItem('userinfo') // 从 localStorage 中删除 token
        // localStorage.removeItem('languageSet')
    },
}

const actions = {
    updateResData({ commit }, data) {
        commit('setToken', data.token)
        commit('setKey', data.key)
        commit('setUserInfo', data)
    },

    async getUserInfo({ commit, state }) {
        request
            .post(
                'index/memberInfo',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        port: 'front',
                        token: localStorage.getItem('token') || null,
                    },
                }
            )
            .then((res) => {
                commit('setUserInfo', res.data)
            })
    },

    // async refreshUserInfo({ dispatch }) {
    //     await dispatch('getUserInfo')
    // },

    logout({ commit }) {
        commit('removeToken') // 删除 token
        commit('removeKey')
        commit('removeUserInfo') // 删除 token
        commit('setIsAuthenticated', false)
        router.replace({ path: '/login' })
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
