import {SESSION_KEY} from '@/config/global'

const porxy = {
    // 最长请求时间
    requestTimeout: 10000,
    // 操作正常code，支持String、Array、int多种类型
    successCode: [200, 0, '200', '0'],
    // 数据状态的字段名称
    statusName: 'code',
    // 状态信息的字段名称
    messageName: 'msg',
    SESSION_KEY,
    contentType: 'application/json;charset=UTF-8',
    tokenName: 'token',
    port: 'front',
    development: {},
}

const obj = {
    host: process.env.NODE_ENV === 'development'
        ? 'http://192.168.1.36/20240302yjh/PHP/public/index.php?s='
        : 'https://mp.yishikang.shop/',
        // : 'http://47.105.44.204:9068/',
    cdn: process.env.NODE_ENV === 'development'
        ? 'http://192.168.1.36/20240302yjh/PHP/public/'
        : 'https://mp.yishikang.shop/',
        // : 'http://47.105.44.204:9068/',
    // isSecurity: false/*true*/,
    isSecurity: process.env.NODE_ENV !== 'development'/*true*/,
}

porxy.development = obj

export default porxy
