<template>
  <div>
    <div style="display: inline-block" @click.stop="openDialog">
      <slot></slot>
    </div>
    <el-dialog
        title="分享商品"
        :append-to-body="true"
        :visible.sync="shareDialog"
        :width="`${dialogWidth}%`">
      <el-image :src="posterImage.url" alt="gomes restaurant" lazy>
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
      </el-image>
    </el-dialog>
  </div>
</template>
<script>
import request from "@/utils/request";

export default {
  props: {
    poster: {
      type: Object,
      default: () => {
        return {
          url: ''
        }
      }
    },
    product: {
      type: Object,
      default: () => {
        return {
          url: ''
        }
      }
    },
    action: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    shareDialog(val) {
      if (val) {
        const screenWidth = window.innerWidth; // 屏幕宽度，单位为px
        if (screenWidth > 900) {
          this.dialogWidth = 30
        } else if (screenWidth < 900 && screenWidth > 500) {
          this.dialogWidth = 50
        }
      }
    }
  },
  data() {
    return {
      shareDialog: false,
      dialogWidth: 80,
      posterImage: {url: ''}
    }
  },
  mounted() {
    if (!this.action) {
      this.posterImage = this.poster
    } else {
      // this.getPoster()
    }
  },
  methods: {
    openDialog() {
      if (this.poster.url === '') {
        this.getPoster(true)
      } else {
        this.posterImage = this.poster
        this.shareDialog = !this.shareDialog
      }
    },
    getPoster(open) {
      console.log('this.product',this.product)
      request.post('shop/product/poster', {product: this.product, api: true}).then(({data}) => {
        this.posterImage = data
        if (open) {
          this.shareDialog = true
        }
      }).catch(({msg}) => {
        if (open) {
          this.$message({type: "error", message: msg})
        }
      })
    }
  }
}
</script>


<style scoped>

</style>