import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import {TastyBurgerButton} from 'vue-tasty-burgers';

Vue.use(TastyBurgerButton);

Vue.config.productionTip = false

Vue.use(ElementUI);
/*Vue.filter('image', function ($v) {
    if (!$v) {
        return '';
    }
    return $v[0] ? $v[0]?.url : '';
});*/

window.jQuery = require('jquery')
// require('/public/js/vendor/jquery-3.3.1.min')
// require('/public/js/vendor/modernizr-3.6.0.min')
// require('/public/js/bootstrap.min')
// require('/public/js/main')
// require('/public/js/plugins')
// require('/public/js/popper.min')

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
