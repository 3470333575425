<template>
  <div id="app">
<!--    <div style="position: sticky;top: 0;">454444</div>-->
    <!--    <nav>-->
    <!--      <router-link to="/">Home</router-link> |-->
    <!--      <router-link to="/about">About</router-link>-->
    <!--    </nav>-->
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;

  .footer_area {
    color: #ffffff;
  }
}

.bloder {
  .el-message__content {
    font-weight: bolder;
    color: rgba(7, 86, 160, 1)!important;
    font-size: 20px
  }

  .el-message__icon {
    color: rgba(7, 86, 160, 1)!important;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
