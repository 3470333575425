<template>
  <div class="col-sm-6 col-6"
       :class="size === 'normal' ? 'col-lg-4 col-xl-3':size === 'small' ? 'col-lg-4 col-xl-2':'col-lg-6 col-xl-4'">
    <!--    <div class="col-sm-6 col-12" :class="size === 'normal' ? 'col-lg-4 col-xl-3':'col-lg-6 col-xl-4'">-->
    <div class="product">
      <div class="thumb product-box button" @click.stop="toItem">
        <div class="pro-img-box">
          <img class="pro-img" :src="info.images[0]['url']" alt="">
        </div>
        <div class="product_action-box">
          <div class="product_action">
            <h4>
              <a>{{ info.product_name }}</a>
            </h4>
            <ul class="cart_action">
              <li>
                <!--              <div @click.stop="addCart()">-->
                <img src="img/icons/add_to_cart.png" alt="">
                <!--              </div>-->
              </li>
              <li v-if="userinfo">
                <div @click.stop="addCollect()">
                  <!-- <i :class="info.collect ? 'el-icon-star-on':'el-icon-star-off'"
                     style="font-size: 27px;color: #ffffff"></i> -->
                  <img :src="info.collect ? 'img/icons/wishlist_icon1.png' : 'img/icons/wishlist_icon.png'" alt="">
                </div>
              </li>
              <li>
                <share-poster :product="info" :action="true">
                  <a title="Quick View" class="quickview">
                    <img src="img/icons/quick_view.png" alt="">
                  </a>
                </share-poster>
              </li>
            </ul>
          </div>
        </div>
        <!--        <div class="content">-->
        <!--          <h4>-->
        <!--            <a>{{ info.product_name }}</a>-->
        <!--          </h4>-->
        <!--          <ul class="price">-->
        <!--            <li class="pro-li">￥ {{ Number(info.product_price) }}</li>-->
        <!--            <li class="pro-li old-price">￥ {{ Number(info.line_price) }}</li>-->
        <!--          </ul>-->
        <!--        </div>-->

        <div class="pro-info">
          <h5 class="pro-name">{{ info.product_name }}</h5>
          <span class="pro-price bolder-price" :class="{'text-mosaic': !userinfo}">￥{{
              Number(info.product_price)
            }} </span>
          <span class="pro-price"
                :class="{'line-price': userinfo, 'text-mosaic': !userinfo}"> ￥{{ Number(info.line_price) }}</span>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import request from "@/utils/request";
import SharePoster from "@/views/components/SharePoster.vue";
import user from "@/store/modules/user";

export default {
  computed: {
    user() {
      return user
    }
  },
  components: {SharePoster},
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          userinfo: JSON.parse(localStorage.getItem('userinfo')),
          images: [],
          product_name: '',
          id: '',
          product_price: '',
          line_price: '',
          sn: ''
        }
      }
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      userinfo: JSON.parse(localStorage.getItem('userinfo'))
    }
  },
  methods: {
    addCart() {
      request.post('shop/cart/addCart', {
        num: 1,
        product_id: this.info.id,
        spec_sku_id: '',
      }).then((res) => {
        setTimeout(() => {
          this.$router.replace({
            path: '/cart',
          })
        }, 100)
      })
    },
    addCollect() {
      console.log('this.info.id',this.info.collect)
      request.post('shop/user/relation/collect', {
        product_id: this.info.id,
      }).then((res) => {
        // this.$message(res.msg);
        this.info.collect = !this.info.collect
        this.$emit('collect')
      })
    },
    toItem() {
      this.$router.push({
        name: 'single-product',
        params: {
          i: this.info.sn,
        }
      });
    }
  }
}
</script>

<style scoped>
.pro-img-box {
  height: 230px;
  overflow: hidden;

  .pro-img {
    width: 100%;
  }
}

.pro-name {
  margin-top: 5px;
  white-space: nowrap;
}

.pro-info {

}

.pro-price {
  white-space: nowrap;
}

.bolder-price {
  font-weight: bolder;
}

.line-price {
  color: #8C8C8C;
  text-decoration: line-through;
}

.product-box {
  background-color: #F6F6F6;
}

.pro-li {
//color: #0E76A8; //font-size: 10px;
}

@media only screen and (max-width: 575px) {
  .product-box {
  //height: 330px; height: 220px;
  }

  .pro-img-box {
    height: 150px;
  }

  .product_action-box {
    display: none;
  }
}
</style>