const state = {
    state: {
        lang: 'cn',
    },
}
const mutations = {
    setLang(state, lang) {
        state.lang = lang
    },
}
const actions = {
    changeLang(context, lang) {
        context.commit('setLang', lang)
    },
}
const getters = {
    lang: (state) => state.id,
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
