import axios from 'axios'
import proxy from '@/config/proxy'
import store from '@/store'
import {decode, encode} from '@/utils/pako'
import {SESSION_KEY} from '@/config/global'
import {Message} from "element-ui";
// import i18n from '@/lang/index'
import router from "@/router";

const env = /*import.meta.env.MODE ||*/ 'development'

let host = env === 'mock' ? '/api' : proxy[env].host // 如果是mock模式 就不配置host 会走本地Mock拦截
const isSecurity = proxy[env]?.isSecurity || false
const {successCode, tokenName, requestTimeout, contentType, statusName, messageName} = proxy

if (proxy.port) {
    host += proxy.port
}
// 操作正常Code数组
const codeVerificationArray = Array.isArray(successCode) ? [...successCode] : [...[successCode]]

const CODE = {
    LOGIN_TIMEOUT: 1000,
    REQUEST_SUCCESS: 200,
    NEED_LOGIN: 401,
    REQUEST_FOBID: 1001,
}

const CODE_MESSAGE = {
    200: '服务器成功返回请求数据',
    201: '新建或修改数据成功',
    202: '一个请求已经进入后台排队(异步任务)',
    204: '删除数据成功',
    400: '发出信息有误',
    401: '用户没有权限(令牌、用户名、密码错误)',
    403: '用户得到授权，但是访问是被禁止的',
    404: '访问资源不存在',
    406: '请求格式不可得',
    410: '请求资源被永久删除，且不会被看到',
    500: '服务器发生错误',
    502: '网关错误',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
}

const instance = axios.create({
    baseURL: host,
    timeout: requestTimeout,
    withCredentials: false,
    headers: {
        'Content-Type': contentType,
    },
})

instance.interceptors.request.use((config) => {
    // const key = SESSION_KEY
    config.headers.PORT = proxy.port
    const token = store.getters['user/token'] || localStorage.getItem('token')
    const key = store.getters['user/key'] || localStorage.getItem('ak');
    if (token) config.headers[tokenName] = token
    if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
        // config.data = qs.stringify(config.data);
    }

    if (config.data && isSecurity && !(config.data instanceof FormData)) {
        // config.headers['Content-Type'] = 'text/plain;charset=UTF-8'
        config.data = encode(JSON.stringify(config.data), key || SESSION_KEY)
    }
    return config
})

instance.defaults.timeout = 30000

const handleData = (response) => {
    let {data} = response
    if (isSecurity && !(data instanceof ArrayBuffer)) {
        // const key = SESSION_KEY
        const key = store.getters['user/key'] || localStorage.getItem('ak');
        let d = decode(data, key || SESSION_KEY)

        if (d === false && key !== SESSION_KEY) {
            d = decode(data, SESSION_KEY)
        }
        if (d !== false) {
            data = JSON.parse(d)
        } else {
            data = {
                code: 401,
                msg: '登录失效，请重新登录',
            };
        }
    }

    // 极个别情况，若将错误code设置为0时，防止识别成false影响判断
    if (data[statusName] === 0) data[statusName] = '0'
    // 若data.code存在，覆盖默认code
    let code = parseInt(data && data[statusName] ? data[statusName] : response.status, 10)
    // 若code属于操作正常code，则status修改为200
    if (codeVerificationArray.includes(code)) code = CODE.REQUEST_SUCCESS
    if (code === CODE.REQUEST_SUCCESS) {
        return data
    }

    let message = ''
    if (data.code === 401) {
        // Message.error('请登录')
        store.dispatch('user/logout').then(() => {
            router.push('/login')
        });
        // console.log('请登录')
        // Vue.$toast.error('tologin')
    } /*else {
        console.log(data.msg)
        Message.error(data.msg)
        // Vue.$toast.error(data.msg)
    }*/
    if (!data) {
        message += `${CODE_MESSAGE[code]}`
    } else {
        message += `${!data[messageName] ? response.statusText : data[messageName]}`
    }

    if (message) {
        // alert(message)
        Message.error(message)
        // MessagePlugin.error({ content: message });
    }

    return Promise.reject(data)
}

instance.interceptors.response.use(
    (response) => handleData(response),
    (err) => {
        const {response} = err;
        if (response === undefined) {
            if (err.config.timeout !== 1000) {
                if (env !== 'release') {
                    Message.error('未可知错误，大部分是由于后端不支持跨域CORS或无效配置引起')
                } else {
                    console.log(err, '未可知错误，大部分是由于后端不支持跨域CORS或无效配置引起')
                }
            }
            return {}
        }
        return handleData(response)
    }
)

export default instance

