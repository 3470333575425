const state = {
    state: {
        id: '0',
    },
}
const mutations = {
    setGoodsId(state, id) {
        state.id = id
    },
}
const actions = {
    updateGoodsId(context, id) {
        context.commit('setGoodsId', id)
    },
}
const getters = {
    goodsId: (state) => state.id,
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
