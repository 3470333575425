<template>
  <!-- Wrapper -->
  <div id="wrapper" class="wrapper" v-loading="loading">

    <!-- Header -->
      <Header />
    <!-- End Header Area -->

    <!-- Cart Overlay -->
    <div class="body_overlay"></div>

    <!-- Start Bradcaump area -->
    <div class="bradcaump_area bg_image--4">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="bradcaump_inner text-center">
              <h2 class="bradcaump-title">登录注册</h2>
              <nav class="bradcaump-content">
                <router-link class="breadcrumb_item" to="/">首页</router-link>
                <span class="brd-separetor">/</span>
                <span class="breadcrumb_item active">登录注册</span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Bradcaump area -->
    <!-- My Account Page -->
    <div class="my-account-area section-ptb-xl">
      <div class="container">
        <div class="row">
          <!-- Login Form -->
          <div class="col-lg-6">
            <div class="login-form-wrapper">
              <div class="sn-form sn-form-boxed">
                <div class="sn-form-inner">
                  <div class="single-input">
                    <label for="login-form-email">手机号</label>
                    <input  v-model="loginForm.mobile" type="text" name="login-form-email" id="login-form-email">
                  </div>
                  <div class="single-input">
                    <label for="login-form-password">密码</label>
                    <input v-model="loginForm.pwd" type="password" name="login-form-password" id="login-form-password">
                  </div>
                  <div class="single-input">
                      <el-button class="mr-3" type="primary" @click="login">
                          登录
                      </el-button>
                  </div>
                  <div class="single-input">
                    <router-link to="/reset-password">忘记密码?</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--// Login Form -->

          <!-- Register Form -->
          <div class="col-lg-6 md-mt--30 sm-mt--30">
            <div class="login-form-wrapper">
              <form action="" class="sn-form sn-form-boxed">
                <div class="sn-form-inner">
<!--                  <div class="single-input">-->
<!--                    <label for="register-form-name">Username</label>-->
<!--                    <input type="text" name="register-form-name" id="register-form-name">-->
<!--                  </div>-->
                  <div class="single-input">
                    <label for="register-form-email">手机号</label>
                    <input v-model="registerForm.mobile"  type="text" name="register-form-email" id="register-form-email">
                  </div>
                  <div class="single-input">
                    <label for="login-form-email">验证码</label>
                      <div class="">
                        <input class="captcha-input" v-model="registerForm.code" type="text" name="login-form-captcha" id="login-form-captcha">
                        <div class="captcha-box" :class="{ opacity: smsTime > 0 }">
                            <div class="captcha-btn button" @click="getCaptcha">
                              {{ smsTime > 0 ? smsTime + '秒后获取' : '获取验证码' }}
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="single-input">
                    <label for="register-form-password">密 码</label>
                    <input v-model="registerForm.pwd"  type="password" name="register-form-password" id="register-form-password">
                  </div>
                  <div class="single-input">
                      <el-button class="mr-3" type="primary" @click="register">
                          注册
                      </el-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!--// Register Form -->
        </div>
      </div>
    </div>
    <!--// My Account Page -->

    <!-- Start Footer Area -->
      <Footer />
    <!-- End Footer Area -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import request from '@/utils/request'
import store from '@/store'
import Header from './components/Header.vue'
import Footer from "@/views/components/Footer.vue";


export default {
  name: 'LoginRegister',
  components: {Footer, Header},
  data() {
    return {
        userinfo: JSON.parse(localStorage.getItem('userinfo')),
        smsTime: 0,
        smsTimer: null,
        loading:false,
        loginForm: {
            mobile: '',
            pwd: ''
       },
        registerForm: {
            mobile: '',
            pwd: ''
        }
    }
  },
  created() {
    if (this.userinfo) {
      this.$router.replace({path: '/',})
    }
  },
  beforeMount() {
    if (this.smsTimer) {
      clearInterval(this.smsTimer)
      this.smsTimer = null
    }
  },
  methods: {
      ...mapActions('user', ['updateResData', 'getUserInfo']),
      login() {
          this.loading = true
          request.post('index/login', this.loginForm).then((res) => {
              this.updateResData(res.data)
              setTimeout(() => {
                  this.$router.replace({
                      path: '/',
                  })
              }, 500)
          }).catch((e) => {

          })
          this.loading = false
      },
      getCaptcha() {
        if (this.smsTime > 0) return

        this.smsTime = 60
        this.smsTimer = setInterval(() => {
          this.smsTime--
          if (this.smsTime < 1) {
            clearInterval(this.smsTimer)
            this.smsTimer = null
          }
        }, 1000)

        request.post('index/sendCode', {
          mobile: this.registerForm.mobile,
          type: 'register'
        }).then((data) => {
          this.$message({
            message: data.msg,
            center: true
          });
          console.log(data)
        }).catch((e) => {
          this.smsTime = 0
          clearInterval(this.smsTimer)
          this.smsTimer = null
        })
      },
      register() {
          this.loading = true
          request.post('index/register', this.registerForm).then((res) => {
              this.$message(res.msg);
              setTimeout(() => {
                  this.loginForm = res.data
                  this.registerForm = {
                      mobile: '',
                      code: '',
                      pwd: ''
                  }
              }, 500)
          }).catch((e) => {
            this.smsTime = 0
            clearInterval(this.smsTimer)
            this.smsTimer = null
          })
          this.loading = false
      }
  }
}
</script>

<style scoped>
.captcha-input {
  width: 60%; float: left
}
.captcha-box {
  width: 40%; float: left;
  padding: 5px;
  .captcha-btn {

  }
}
.opacity {
  opacity: 0.5;
}
@media only screen and (max-width: 575px) {
  .captcha-input {
    width: 50%; float: left
  }
  .captcha-box {
    width: 50%; float: left;
  }
}

label {
  text-align: left;
}

</style>
